import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n, { changeLanguage } from "./i18n";

export default function Home() {
  const [inputValue, setInputValue] = useState<string>("");
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(
      event.target.value.replace(/[^a-zåäö\n ]/gi, "").toUpperCase()
    );
  };

  const letterToWordDictionary: Record<string, string> = {
    A: t("A"),
    B: t("B"),
    C: t("C"),
    D: t("D"),
    E: t("E"),
    F: t("F"),
    G: t("G"),
    H: t("H"),
    I: t("I"),
    J: t("J"),
    K: t("K"),
    L: t("L"),
    M: t("M"),
    N: t("N"),
    O: t("O"),
    P: t("P"),
    Q: t("Q"),
    R: t("R"),
    S: t("S"),
    T: t("T"),
    U: t("U"),
    V: t("V"),
    W: t("W"),
    X: t("X"),
    Y: t("Y"),
    Z: t("Z"),
    Å: t("Å"),
    Ä: t("Ä"),
    Ö: t("Ö"),
  };

  function replaceLettersWithWords(
    text: string,
    dictionary: Record<string, string>
  ): string {
    // Split the input text into an array of characters
    const characters = text.split("");

    // Map each character to its corresponding word or keep it unchanged
    const replacedText = characters
      .map((char) => dictionary[char] || char)
      .join(" ");

    console.log(replacedText);
    return replacedText;
  }

  // Function to switch between languages
  const switchLanguage = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="w-full bg-gray-800 p-4">
        <div className="flex justify-end">
          <button
            className={`mr-2 ${
              i18n.language === "sv" ? "bg-slate-500 outline outline-2 outline-slate-500/50" : "bg-slate-400"
            } p-2 rounded-md`}
            onClick={() => changeLanguage("sv")} // Change to Swedish
          >
            {t("swedish")}
          </button>
          <button
            className={`mr-2 ${
              i18n.language === "en" ? "bg-slate-500 outline outline-2 outline-slate-500/50" : "bg-slate-400"
            } p-2 rounded-md`}
            onClick={() => changeLanguage("en")} // Change to English
          >
            {t("english-nato")}
          </button>
        </div>
      </header>
      <main className="flex-grow flex flex-col items-center p-24 bg-slate-800">
        <h1 className="text-4xl md:text-6xl font-semibold mb-8">
          {t("title")}
        </h1>
        <h5 className="text-xl mb-4">{t("tagline")}</h5>
        <div className="w-80">
          <input
             type="text"
            placeholder={t("input-placeholder")}
            className="w-full border py-2 px-3 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-slate-500"
            value={inputValue}
            onChange={handleInputChange}
          />
          <div className="mt-4 w-80">
            <h3 className="text-2xl">
              {replaceLettersWithWords(inputValue, letterToWordDictionary)}
            </h3>
          </div>
        </div>
      </main>
      <footer className="text-center p-4 bg-gray-800 text-gray-300">
        <a href="https://erikmartinandersson.se" target="_blank" rel="noopener noreferrer">{t("creator-link-description")}</a>
      </footer>
    </div>
  );
}