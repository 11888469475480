import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "title": "Bokstavering.se",
      "tagline": "Learn the phonetic alphabet",
      "input-placeholder": "Write your text here..",
      "swedish": "Swedish",
      "english-nato": "English (NATO)",
      "creator-link-description": "Created by Martin 🤓",
      "A": "Alfa",
      "B": "Bravo",
      "C":	"Charlie",
      "D":	"Delta",
      "E":	"Echo",
      "F":	"Foxtrot",
      "G":	"Golf",
      "H":	"Hotel",
      "I":	"India",
      "J":	"Juliett",
      "K":	"Kilo",
      "L":	"Lima",
      "M":	"Mike",
      "N":	"November",
      "O":	"Oscar",
      "P":	"Papa",
      "Q":	"Quebec",
      "R":	"Romeo",
      "S":	"Sierra",
      "T":	"Tango",
      "U":	"Uniform",
      "V":	"Victor",
      "W":	"Whiskey",
      "X":	"Xray",
      "Y":	"Yankee",
      "Z":	"Zulu",
    },
  },
  sv: {
    translation: {
      "title": "Bokstavering.se",
      "tagline": "Lär dig bokstaveringsalfabetet",
      "input-placeholder": "Skriv in din text här..",
      "swedish": "Svenska",
      "english-nato": "Engelska (NATO)",
      "creator-link-description": "Skapad av Martin 🤓",
      "A": "Adam",
      "B": "Bertil",
      "C": "Cesar",
      "D": "David",
      "E": "Erik",
      "F": "Filip",
      "G": "Gustav",
      "H": "Helge",
      "I": "Ivar",
      "J": "Johan",
      "K": "Kalle",
      "L": "Ludvig",
      "M": "Martin",
      "N": "Niklas",
      "O": "Olle",
      "P": "Petter",
      "Q": "Qvintus",
      "R": "Rudolf",
      "S": "Sigurd",
      "T": "Tore",
      "U": "Urban",
      "V": "Viktor",
      "W": "Wilhelm",
      "X": "Xerxes",
      "Y": "Yngve",
      "Z": "Zäta",
      "Å": "Åke",
      "Ä": "Ärlig",
      "Ö": "Östen",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "sv",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export const changeLanguage = (newLanguage: string | undefined) => {
  i18n.changeLanguage(newLanguage);
};

export default i18n;
